import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AuthService from "@/services/auth-service";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Welcome',
        component: () => import('../views/Welcome.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('../views/Register.vue'),
        meta: {
            requiresAuth: false
        }
    }, {
        path: '/signin',
        name: 'Sign In',
        component: () => import('../views/Signin.vue'),
        meta: {
            requiresAuth: false
        }
    }, {
        path: '/plans',
        name: 'Plans',
        component: () => import('../views/Plans.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/payment-success',
        name: 'Payment Success',
        component: () => import('../views/PaymentSuccess.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/payment-fail',
        name: 'Payment Fail',
        component: () => import('../views/PaymentFail.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/payment-process',
        name: 'Payment Process',
        component: () => import('../views/PaymentProcess.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/trial-activation',
        name: 'Trial Activation',
        component: () => import('../views/TrialActivation.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/trial-activation-success',
        name: 'Trial Activation Success',
        component: () => import('../views/TrialActivationSuccess.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/events',
        name: 'Events',
        component: () => import('../views/Events.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/account',
        name: 'Account',
        component: () => import('../views/Account.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/change-password',
        name: 'Change Password',
        component: () => import('../views/ChangePassword.vue'),
        meta: {
            requiresAuth: false
        }
    }, {
        path: '/payment-history',
        name: 'Payment History',
        component: () => import('../views/PaymentHistory.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/contact-support',
        name: 'Contact Support',
        component: () => import('../views/ContactSupport.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/player',
        name: 'Player',
        component: () => import('../views/Player.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/secure-payment',
        name: 'Secure payment',
        component: () => import('../views/SecurePayment.vue'),
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/acceptable-use-policy',
        name: 'Acceptable Use Policy',
        component: () => import('../views/AcceptableUsePolicy.vue'),
        meta: {
            requiresAuth: false
        }
    }, {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: () => import('../views/PrivacyPolicy.vue'),
        meta: {
            requiresAuth: false
        }
    }, {
        path: '/terms-of-service',
        name: 'Terms Of Service',
        component: () => import('../views/TermsOfService.vue'),
        meta: {
            requiresAuth: false
        }
    }, {
        path: '/cookie-policy',
        name: 'Cookie Policy',
        component: () => import('../views/CookiePolicy.vue'),
        meta: {
            requiresAuth: false
        }
    }, {
        path: '/refund-policy',
        name: 'Refund Policy',
        component: () => import('../views/RefundPolicy.vue'),
        meta: {
            requiresAuth: false
        }
    }, {
        path: '/reset-password',
        name: 'Reset password',
        component: () => import('../views/ResetPassword.vue'),
        meta: {
            requiresAuth: false
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    window.scrollTo(0,0);
    if (to.path !== "/") {
        document.title = "Procricket.tv"
        if (to.meta.requiresAuth) {
            if (!AuthService.isSignedIn()) {
                return next('/signin')
            }
        }
    } else {
        document.title = "Procricket.tv - Watch Live Cricket Streaming Online in HD"
        if (AuthService.isSignedIn()) {
            return next('/events')
        }
    }


    next()
})

export default router
