import apiClient from '@/utils/api-client'
import AuthService from '@/services/auth-service'

const setup = () => {
  apiClient.interceptors.request.use(
      (config) => {
        if (config.headers === undefined) {
          config.headers = {};
        }
        const token = localStorage.getItem('access_token')
        if (token) {
          config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
  );

  apiClient.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;

        if (err.response) {
          // Access Token was expired
          if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;
            try {
              const rs = await AuthService.refreshToken()
              return apiClient(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }

        return Promise.reject(err);
      }
  );
};

export default setup;