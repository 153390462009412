import axios, { AxiosInstance } from 'axios'

const authClient: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_OAUTH_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic cHJvY3JpY2tldDowMmQ0NzFlMC1kNDZhLTRjMDUtYmY5OS01MzA0NWRkNmYzZWI'
    }
})

export default authClient
